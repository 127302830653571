/**
 * @preserve version: 1.1.238-main
 */

(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
function _extends() {
  module.exports = _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

module.exports = _extends;
},{}],2:[function(require,module,exports){
function _typeof(obj) {
  "@babel/helpers - typeof";

  if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") {
    module.exports = _typeof = function _typeof(obj) {
      return typeof obj;
    };
  } else {
    module.exports = _typeof = function _typeof(obj) {
      return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };
  }

  return _typeof(obj);
}

module.exports = _typeof;
},{}],3:[function(require,module,exports){
var serviceCollection = {};

var getLogger = function getLogger() {
  if (!!serviceCollection.logger) return serviceCollection.logger;
  return {
    logError: function logError(title, error) {
      var args = [];

      for (var _i = 2; _i < arguments.length; _i++) {
        args[_i - 2] = arguments[_i];
      }

      console.error(title, error, args);
    }
  };
};

(function () {
  try {
    serviceCollection.logger = require('./modules/LoggingService');
    serviceCollection.analyticsService = require('./modules/AnalyticsService');
    serviceCollection.eventCalculatorService = require('./modules/features/EventCalculatorService');
    var analyticsWindow = window;
    analyticsWindow.analyticsService = serviceCollection.analyticsService;
    analyticsWindow.analyticsTealiumServices = analyticsWindow.analyticsTealiumServices || {};
    analyticsWindow.analyticsTealiumServices.calculateEvents = serviceCollection.eventCalculatorService.calculateEvents;
  } catch (e) {
    getLogger().logError('Could not create analytics library', e);
  }

  try {
    serviceCollection.analyticsVisitorService = require('./modules/features/AnalyticsVisitorService');
    serviceCollection.analyticsVisitorService.setVisitorId();
  } catch (e) {
    getLogger().logError('Could load visitor id service', e);
  }
})();

},{"./modules/AnalyticsService":4,"./modules/LoggingService":7,"./modules/features/AnalyticsVisitorService":13,"./modules/features/EventCalculatorService":14}],4:[function(require,module,exports){
"use strict";

exports.__esModule = true;
exports.sendLinkEvent = exports.sendViewEvent = void 0;

var ViewEvent_1 = require("./events/ViewEvent");

var LinkEvent_1 = require("./events/LinkEvent");

var eventDispatchService = require("./EventDispatchService");

var logger = require("./LoggingService");

var tryWithLogging = function tryWithLogging(func) {
  try {
    func();
  } catch (e) {
    logger.logError(e.name, e);
  }
};

var sendViewEvent = function sendViewEvent(eventName, viewEventAnalytics) {
  tryWithLogging(function () {
    var viewEvent = new ViewEvent_1.ViewEvent(eventName, viewEventAnalytics);
    eventDispatchService.sendEvent(viewEvent);
  });
};

exports.sendViewEvent = sendViewEvent;

var sendLinkEvent = function sendLinkEvent(eventName, linkEventAnalytics, linkContext) {
  tryWithLogging(function () {
    var linkEvent = new LinkEvent_1.LinkEvent(eventName, linkEventAnalytics);
    eventDispatchService.sendEvent(linkEvent);
  });
};

exports.sendLinkEvent = sendLinkEvent;

},{"./EventDispatchService":6,"./LoggingService":7,"./events/LinkEvent":10,"./events/ViewEvent":11}],5:[function(require,module,exports){
"use strict";

exports.__esModule = true;
exports.setValue = exports.getValue = void 0;

var HostnameService_1 = require("./features/HostnameService");

var getValue = function getValue(name) {
  var parts = ("; " + document.cookie).split("; " + name + "=");
  return parts.length === 2 ? parts.pop().split(';').shift() : undefined;
};

exports.getValue = getValue;

var setValue = function setValue(name, value, date) {
  var cookieDomain = HostnameService_1.getBaseDomain();
  var cookie = name + "=" + value;
  var expires = "expires=" + date.toUTCString();
  var domain = cookieDomain ? 'domain=' + cookieDomain : '';
  var rootPath = 'path=/';
  document.cookie = cookie + ";" + expires + ";" + rootPath + ";" + domain;
};

exports.setValue = setValue;

},{"./features/HostnameService":15}],6:[function(require,module,exports){
"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");

exports.__esModule = true;
exports.sendEvent = void 0;

var trackingService = require("./tealium-services/TealiumTrackingService");

var logger = require("./LoggingService");

var AnalyticsEvent_1 = require("./events/AnalyticsEvent");

var deferredEventQueue = [];
var deferIntervalId = null;

var sendToTealium = function sendToTealium(event) {
  try {
    logger.logDebug('attempting to send event', event);
    trackingService.track(event.getMethodType(), event.getAnalytics());
  } catch (e) {
    logger.logError('cannot send tealium event', {
      e: e.toString(),
      stack: e.stack
    });
  }
};

var stopQueueInterval = function stopQueueInterval() {
  deferredEventQueue = [];
  clearInterval(deferIntervalId);
  deferIntervalId = null;
};

var startQueueInterval = function startQueueInterval() {
  if (!trackingService.isActive()) {
    logger.logWarning('Tealium still not available', deferredEventQueue.length + " events in Queue", deferredEventQueue);
  }

  deferIntervalId = setInterval(function () {
    if (!trackingService.isActive()) {
      return;
    }

    for (var _i = 0, deferredEventQueue_1 = deferredEventQueue; _i < deferredEventQueue_1.length; _i++) {
      var deferredEvent = deferredEventQueue_1[_i];
      sendToTealium(deferredEvent);
    }

    stopQueueInterval();
  }, 25);
};

var deferEvent = function deferEvent(deferredEvent) {
  deferredEventQueue.push(deferredEvent);
  var isDeferred = !!deferIntervalId;
  if (!isDeferred) startQueueInterval();
};

var isValidEventType = function isValidEventType(analyticsEvent) {
  if (analyticsEvent instanceof AnalyticsEvent_1.AnalyticsEvent) return true;
  logger.logError("analytics event type is invalid, recieved " + _typeof(analyticsEvent));
  return false;
};

var sendEvent = function sendEvent(analyticsEvent) {
  if (!isValidEventType(analyticsEvent)) return null;
  if (trackingService.isActive()) return sendToTealium(analyticsEvent);
  deferEvent(analyticsEvent);
};

exports.sendEvent = sendEvent;

},{"./LoggingService":7,"./events/AnalyticsEvent":8,"./tealium-services/TealiumTrackingService":18,"@babel/runtime/helpers/typeof":2}],7:[function(require,module,exports){
"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");

function _objectEntries(obj) {
  var entries = [];
  var keys = Object.keys(obj);

  for (var k = 0; k < keys.length; k++) entries.push([keys[k], obj[keys[k]]]);

  return entries;
}

exports.__esModule = true;
exports.logError = exports.logWarning = exports.logInfo = exports.logDebug = void 0;

var TealiumLoggingService_1 = require("./tealium-services/TealiumLoggingService");

var AnalyticsVersionNumberService_1 = require("./features/AnalyticsVersionNumberService");

var tealiumProfile = 'stepstone-uk';

var getFromUtag = function getFromUtag(key) {
  try {
    return window.utag_data.analytics[key];
  } catch (e) {
    return 'not available';
  }
};

var convertToString = function convertToString(value) {
  if (_typeof(value) == 'object') return JSON.stringify(value);
  return new String(value);
};

var logDebug = function logDebug(title) {
  var args = [];

  for (var _i = 1; _i < arguments.length; _i++) {
    args[_i - 1] = arguments[_i];
  }

  console.debug(title, args);
};

exports.logDebug = logDebug;

var logInfo = function logInfo(title) {
  var args = [];

  for (var _i = 1; _i < arguments.length; _i++) {
    args[_i - 1] = arguments[_i];
  }

  console.log(title, args);
};

exports.logInfo = logInfo;

var logWarning = function logWarning(title) {
  var args = [];

  for (var _i = 1; _i < arguments.length; _i++) {
    args[_i - 1] = arguments[_i];
  }

  console.warn(title, args);
};

exports.logWarning = logWarning;

var logError = function logError(title, error) {
  var args = [];

  for (var _i = 2; _i < arguments.length; _i++) {
    args[_i - 2] = arguments[_i];
  }

  console.error(title, error, args);
  error = error || {};
  var errorDetails = JSON.stringify(args).match(/.{1,1000}/g);
  var errorObject = {
    'error.title': title,
    'error.name': error.name,
    'error.stack': error.stack,
    'error.message': error.message,
    'log.details': errorDetails
  };
  console.log(errorObject);

  for (var _a = 0, _b = _objectEntries(error); _a < _b.length; _a++) {
    var _c = _b[_a],
        key = _c[0],
        value = _c[1];
    errorObject['error.' + key] = convertToString(value);
  }

  errorObject['analytics.pageName'] = getFromUtag('pageName');
  errorObject['currentUrl'] = window.location.href;
  errorObject['analytics.libraryVersion'] = AnalyticsVersionNumberService_1.AnalyticsVersionNumber;
  TealiumLoggingService_1.sendTealiumLogEvent(errorObject, tealiumProfile, 'analytics-error');
};

exports.logError = logError;

},{"./features/AnalyticsVersionNumberService":12,"./tealium-services/TealiumLoggingService":17,"@babel/runtime/helpers/typeof":2}],8:[function(require,module,exports){
"use strict";

var _extends = require("@babel/runtime/helpers/extends");

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

exports.__esModule = true;
exports.AnalyticsEvent = void 0;

var analyticsDataLayer = require("../tealium-services/AnalyticsDataLayer");

var analyticsVisitorService = require("../features/AnalyticsVisitorService");

var AnalyticsVersionNumberService_1 = require("../features/AnalyticsVersionNumberService");

var isObject = function isObject(obj) {
  return Object.prototype.toString.call(obj) === '[object Object]';
};

var isLegacy = function isLegacy(key) {
  return key.toLowerCase() === 'legacy';
};

var isPii = function isPii(key) {
  return key.toLowerCase() === 'pii';
};

var isCategory = function isCategory(key) {
  return isLegacy(key) || isPii(key);
};

var flattenObject = function flattenObject(obj, prefix) {
  if (prefix === void 0) {
    prefix = '';
  }

  return Object.keys(obj).reduce(function (accumulation, item) {
    var pre = prefix.length ? prefix + '.' : '';
    if (isLegacy(item) || isLegacy(prefix)) pre = '';
    if (isObject(obj[item]) && isCategory(item)) _extends(accumulation, flattenObject(obj[item], pre + item));else accumulation[pre + item] = obj[item];
    return accumulation;
  }, {});
};

var AnalyticsEvent = function () {
  function AnalyticsEvent(eventName, eventType, eventAnalytics) {
    var _this = this;

    this.analytics = {};

    this.getMethodType = function () {
      return "" + _this.eventType;
    };

    this.getSanitisedCategory = function (category) {
      if (!category || !isObject(category)) return {};
      Object.keys(category).forEach(function (key) {
        if (key.match(/^analytics\./i)) delete category[key];
      });
      return category;
    };

    this.flattenAnalytics = function (analytics) {
      analytics = __assign({}, analytics);
      var harmonisedAnalytics = {};
      Object.keys(analytics).forEach(function (key) {
        if (!isCategory(key)) harmonisedAnalytics[key] = analytics[key];
      });

      var sanitisedAnalytics = __assign(__assign({}, harmonisedAnalytics), {
        pii: _this.getSanitisedCategory(analytics.pii),
        legacy: _this.getSanitisedCategory(analytics.legacy)
      });

      return flattenObject(sanitisedAnalytics, 'analytics');
    };

    this.buildAnalytics = function () {
      return _extends({}, __assign(__assign(__assign({}, _this.flattenAnalytics(analyticsDataLayer.getSharedAnalytics())), _this.flattenAnalytics(_this.eventAnalytics)), {
        'analytics.eventName': _this.eventName,
        'analytics.visitorId': analyticsVisitorService.getVisitorId(),
        'analytics.fallbackVisitorId': analyticsVisitorService.getFallbackVisitorId(),
        'analytics.libraryVersion': AnalyticsVersionNumberService_1.AnalyticsVersionNumber,
        analytics: null
      }));
    };

    this.getAnalytics = function () {
      return _this.analytics;
    };

    if (!eventName || typeof eventName !== 'string' || eventName.length < 1) throw Error('event name must be defined and not empty');
    if (!eventType || eventType.length < 1) throw Error('method type must be defined and not empty');
    if (!eventAnalytics || !isObject(eventAnalytics)) throw Error('event analytics must be defined');
    this.eventName = eventName;
    this.eventType = eventType;
    this.eventAnalytics = eventAnalytics;
    this.analytics = this.buildAnalytics();
  }

  return AnalyticsEvent;
}();

exports.AnalyticsEvent = AnalyticsEvent;

},{"../features/AnalyticsVersionNumberService":12,"../features/AnalyticsVisitorService":13,"../tealium-services/AnalyticsDataLayer":16,"@babel/runtime/helpers/extends":1}],9:[function(require,module,exports){
"use strict";

exports.__esModule = true;
exports.view = exports.link = void 0;
var link = 'link';
exports.link = link;
var view = 'view';
exports.view = view;

},{}],10:[function(require,module,exports){
"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

exports.__esModule = true;
exports.LinkEvent = void 0;

var AnalyticsEvent_1 = require("./AnalyticsEvent");

var eventType = require("./EventType");

var LinkEvent = function (_super) {
  __extends(LinkEvent, _super);

  function LinkEvent(eventName, eventAnalytics, linkContext) {
    return _super.call(this, eventName, eventType.link, eventAnalytics) || this;
  }

  return LinkEvent;
}(AnalyticsEvent_1.AnalyticsEvent);

exports.LinkEvent = LinkEvent;

},{"./AnalyticsEvent":8,"./EventType":9}],11:[function(require,module,exports){
"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

exports.__esModule = true;
exports.ViewEvent = void 0;

var AnalyticsEvent_1 = require("./AnalyticsEvent");

var eventType = require("./EventType");

var ViewEvent = function (_super) {
  __extends(ViewEvent, _super);

  function ViewEvent(eventName, eventAnalytics) {
    return _super.call(this, eventName, eventType.view, eventAnalytics) || this;
  }

  return ViewEvent;
}(AnalyticsEvent_1.AnalyticsEvent);

exports.ViewEvent = ViewEvent;

},{"./AnalyticsEvent":8,"./EventType":9}],12:[function(require,module,exports){
"use strict";

exports.__esModule = true;
exports.AnalyticsVersionNumber = void 0;
var AnalyticsVersionNumber = 'TEMPLATE_3d4fe5f1b1b';
exports.AnalyticsVersionNumber = AnalyticsVersionNumber;

},{}],13:[function(require,module,exports){
"use strict";

exports.__esModule = true;
exports.setVisitorId = exports.getFallbackVisitorId = exports.getVisitorId = void 0;

var logger = require("../LoggingService");

var cookie = require("../Cookie");

var visitorIdRegex = /[a-fA-Z0-9]{16}-[a-fA-Z0-9]{16}/;
var aidName = 's_vi';
var customUkAidName = 'sc_vid';
var fidName = 's_fid';
var jobsiteBrands = ['jobsite', 'justengineers', 'cityjobs', 'emedcareers'];
var Window = window;

var isContinentalSite = function isContinentalSite() {
  return Window.location.origin.indexOf('stepstone') > -1;
};

var isJobsiteBrand = function isJobsiteBrand() {
  for (var _i = 0, jobsiteBrands_1 = jobsiteBrands; _i < jobsiteBrands_1.length; _i++) {
    var brand = jobsiteBrands_1[_i];
    if (Window.location.origin.indexOf(brand) > -1) return true;
  }

  return false;
};

var getVid = function getVid() {
  if (!!Window.s) return Window.s.visitorID;
  return undefined;
};

var getAid = function getAid() {
  var aid = cookie.getValue(aidName);

  if (!!aid) {
    if (aid.match(visitorIdRegex)) return aid.match(visitorIdRegex)[0];
    logger.logError("Unexpected format for AID: \"" + aid + "\"");
  }

  return aid;
};

var getCustomUkAid = function getCustomUkAid() {
  return cookie.getValue(customUkAidName);
};

var getFid = function getFid() {
  return cookie.getValue(fidName);
};

var visitorIdStrategies = [getVid, getCustomUkAid, getAid, getFid];

var getVisitorId = function getVisitorId() {
  for (var _i = 0, visitorIdStrategies_1 = visitorIdStrategies; _i < visitorIdStrategies_1.length; _i++) {
    var strategy = visitorIdStrategies_1[_i];
    var value = strategy();
    if (!!value) return value;
  }

  return undefined;
};

exports.getVisitorId = getVisitorId;

var generateAlphaNumeric = function generateAlphaNumeric(length) {
  var a = '';
  var b = 'abcdef1234567890'.split('');

  for (var n = 0; n < length; n++) {
    a += b[Math.round(Math.random() * (b.length - 1))];
  }

  return a;
};

var setCookie = function setCookie(name, value, years) {
  var date = new Date();
  date.setTime(date.getTime() + years * 365 * 24 * 60 * 60 * 1000);
  cookie.setValue(name, value, date);
};

var setCeCookies = function setCeCookies() {
  var cookieValue = generateAlphaNumeric(16) + "-" + generateAlphaNumeric(16);
  setCookie(aidName, "[CS]v1|" + cookieValue + "[CE]", 2);
  setCookie(fidName, cookieValue, 2);
};

var setCustomUkAid = function setCustomUkAid() {
  var cookieValue = generateAlphaNumeric(32);
  setCookie(customUkAidName, generateAlphaNumeric(32), 5);
  var fidCookieValue = cookieValue.slice(0, 16) + '-' + cookieValue.slice(16);
  setCookie(fidName, fidCookieValue, 5);
};

var getFallbackVisitorId = getFid;
exports.getFallbackVisitorId = getFallbackVisitorId;

var setVisitorId = function setVisitorId() {
  if (getVisitorId() != undefined || isJobsiteBrand()) return;
  isContinentalSite() ? setCeCookies() : setCustomUkAid();
};

exports.setVisitorId = setVisitorId;

},{"../Cookie":5,"../LoggingService":7}],14:[function(require,module,exports){
"use strict";

function _objectEntries(obj) {
  var entries = [];
  var keys = Object.keys(obj);

  for (var k = 0; k < keys.length; k++) entries.push([keys[k], obj[keys[k]]]);

  return entries;
}

exports.__esModule = true;
exports.calculateEvents = void 0;

var logger = require("../LoggingService");

var utagKeys = Object.freeze({
  harmonised: {
    calculatedEvents: 'analytics.calculatedEvents',
    analyticsEvents: 'analytics.events'
  },
  legacy: {
    calculatedEvents: 'analytics.legacyCalculatedEvents',
    analyticsEvents: 'events'
  }
});
var cachedConfigs = {};

var distinctArray = function distinctArray(a) {
  return a.sort().filter(function (item, pos, ary) {
    return !pos || item != ary[pos - 1];
  });
};

var getApplicableEvents = function getApplicableEvents(eventsLiterals, eventKeys) {
  var events = [];
  var missingEvents = [];
  eventsLiterals.forEach(function (literalEvent) {
    var eventNumbers = eventKeys[literalEvent] || undefined;
    if (!!eventNumbers) eventNumbers.forEach(function (eventNumber) {
      return events.push(eventNumber);
    });else missingEvents.push(literalEvent);
  });
  if (missingEvents.length > 0) logger.logError("Could not find event mapping for the following alias'", null, missingEvents);
  var ae = {
    events: events,
    missingEvents: missingEvents
  };
  return ae;
};

var getEnrichmentMappings = function getEnrichmentMappings(config, cacheKey) {
  if (!!cachedConfigs[cacheKey]) return cachedConfigs[cacheKey];

  if (!config) {
    logger.logError('configuration for event enrichment unavailable', {}, "config: " + config, "config type: " + cacheKey);
    config = {};
  }

  var getEventToDataMappings = function getEventToDataMappings(dataToEventMappings) {
    dataToEventMappings = dataToEventMappings || {};
    var eventDataMappings = {};

    var _loop_1 = function _loop_1(dataKey, associatedEvent) {
      (associatedEvent || []).forEach(function (valueEvent) {
        return eventDataMappings["" + valueEvent] = dataKey;
      });
    };

    for (var _i = 0, _a = _objectEntries(dataToEventMappings); _i < _a.length; _i++) {
      var _b = _a[_i],
          dataKey = _b[0],
          associatedEvent = _b[1];

      _loop_1(dataKey, associatedEvent);
    }

    return eventDataMappings;
  };

  return cachedConfigs[cacheKey] = {
    eventKeys: config.eventKeys || {},
    eventSerials: getEventToDataMappings(config.eventSerials),
    eventValues: getEventToDataMappings(config.eventValues)
  };
};

var getEnrichedEvents = function getEnrichedEvents(applicableEvents, eventValuesMap, eventSerialsMap, utag_data) {
  var eventIds = applicableEvents.events,
      missingEvents = applicableEvents.missingEvents;
  var enrichedEvents = eventIds.map(function (eventId) {
    var getMapData = function getMapData(seperator, source) {
      var eventValue = utag_data[source[eventId]];
      return !!eventValue ? "" + seperator + eventValue : '';
    };

    return "event" + eventId + getMapData('=', eventValuesMap) + getMapData(':', eventSerialsMap);
  });
  return distinctArray(enrichedEvents);
};

var calculateEventsByConfig = function calculateEventsByConfig(eventType, utag_data, enrichmentConfig, utagKeys) {
  try {
    var enrichmentMappings = getEnrichmentMappings(enrichmentConfig, utagKeys.analyticsEvents);
    var currentEventLiterals = utag_data[utagKeys.analyticsEvents] || [];
    var applicableEvents = getApplicableEvents(currentEventLiterals, enrichmentMappings.eventKeys);
    utag_data[utagKeys.calculatedEvents] = getEnrichedEvents(applicableEvents, enrichmentMappings.eventValues, enrichmentMappings.eventSerials, utag_data);
  } catch (error) {
    logger.logError('failed to calculate events', error, {
      eventType: eventType,
      enrichmentConfig: enrichmentConfig
    });
  }
};

var isEmptyObject = function isEmptyObject(obj) {
  return Object.keys(obj).length === 0;
};

var calculateEvents = function calculateEvents(eventType, utag_data) {
  if (!eventType || !utag_data || isEmptyObject(utag_data)) {
    logger.logError('could not calculate events, event type or utag_data not defined', {}, {
      eventType: eventType,
      utag_data: utag_data
    });
    return;
  }

  var harmonisedEnrichmentConfig = utag_data['analyticsConfig.harmonised'];
  var legacyEnrichmentConfig = utag_data['analyticsConfig.legacy'];
  calculateEventsByConfig(eventType, utag_data, harmonisedEnrichmentConfig, utagKeys.harmonised);
  if (!!legacyEnrichmentConfig) calculateEventsByConfig(eventType, utag_data, legacyEnrichmentConfig, utagKeys.legacy);
  delete utag_data['analyticsConfig.harmonised'];
  delete utag_data['analyticsConfig.legacy'];
};

exports.calculateEvents = calculateEvents;

},{"../LoggingService":7}],15:[function(require,module,exports){
"use strict";

exports.__esModule = true;
exports.getBaseDomain = void 0;

var getBaseDomain = function getBaseDomain() {
  var hostName = window.location.hostname;
  var cookieDomain = null,
      lastIndex = hostName.lastIndexOf('.'),
      cookieDomainPeriods = window.document.domain.split('.').length - 1;
  cookieDomainPeriods = 2 < cookieDomainPeriods ? cookieDomainPeriods : 2;

  if (!/^[0-9.]+$/.test(hostName)) {
    for (; 0 <= lastIndex && 1 < cookieDomainPeriods;) {
      lastIndex = hostName.lastIndexOf('.', lastIndex - 1);
      cookieDomainPeriods--;
      cookieDomain = 0 < lastIndex ? hostName.substring(lastIndex) : hostName;
    }
  }

  return cookieDomain;
};

exports.getBaseDomain = getBaseDomain;

},{}],16:[function(require,module,exports){
"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

exports.__esModule = true;
exports.getSharedAnalytics = void 0;

var logger = require("../LoggingService");

var isObject = function isObject(obj) {
  return Object.prototype.toString.call(obj) === '[object Object]';
};

var getSharedAnalytics = function getSharedAnalytics() {
  if (!!window.utag_data && !!window.utag_data.analytics && isObject(window.utag_data.analytics)) return __assign({}, window.utag_data.analytics);
  logger.logError('Cannot get page analytics', {}, window.utag_data);
  return {};
};

exports.getSharedAnalytics = getSharedAnalytics;

},{"../LoggingService":7}],17:[function(require,module,exports){
"use strict";

exports.__esModule = true;
exports.sendTealiumLogEvent = void 0;
var tealiumCollectUrl = 'https://collect.tealiumiq.com/event';

var getUtagDataObject = function getUtagDataObject(key) {
  var utag = window.utag || {};
  var data = utag.data || {};
  return data[key] || '';
};

var createTealiumLogData = function createTealiumLogData(mapping, tealiumProfile, tealiumEvent) {
  var tealiumEventData = {
    'data': {},
    tealium_account: 'stepstone',
    tealium_profile: tealiumProfile,
    tealium_event: tealiumEvent,
    tealium_visitor_id: getUtagDataObject('cp.sc_vid') || getUtagDataObject('ut.visitor_id'),
    'ut.env': getUtagDataObject('ut.env')
  };

  if (getUtagDataObject('cp.trace_id') !== undefined) {
    tealiumEventData.tealium_trace_id = getUtagDataObject('cp.trace_id');
  }

  for (var attribute in mapping) {
    tealiumEventData[attribute] = mapping[attribute];
  }

  return tealiumEventData;
};

var sendViaBeacon = function sendViaBeacon(eventPayload, eventName) {
  navigator.sendBeacon(tealiumCollectUrl, eventPayload);
};

var sendViaXhr = function sendViaXhr(eventPayload, eventName) {
  var xhr = new XMLHttpRequest();
  xhr.open('POST', tealiumCollectUrl);

  var transferComplete = function transferComplete() {
    return console.debug('Tealium event: (' + eventName + ') transfer successful.');
  };

  var transferFailed = function transferFailed() {
    return console.error('An error occurred while sending tealium event (' + eventName + ').');
  };

  var transferCanceled = function transferCanceled() {
    return console.warn('The tealium event (' + eventName + ') transfer has been cancelled ');
  };

  xhr.addEventListener('load', transferComplete);
  xhr.addEventListener('error', transferFailed);
  xhr.addEventListener('abort', transferCanceled);
  xhr.send(eventPayload);
};

var sendEvent = function sendEvent(eventPayload, eventName) {
  return !!navigator.sendBeacon ? sendViaBeacon(eventPayload, eventName) : sendViaXhr(eventPayload, eventName);
};

var sendTealiumLogEvent = function sendTealiumLogEvent(mapping, tealiumProfile, eventName) {
  var tealiumEventData = createTealiumLogData(mapping, tealiumProfile, eventName);
  var evtPayload = JSON.stringify(tealiumEventData);
  sendEvent(evtPayload, eventName);
};

exports.sendTealiumLogEvent = sendTealiumLogEvent;

},{}],18:[function(require,module,exports){
"use strict";

exports.__esModule = true;
exports.track = exports.isActive = void 0;

var logger = require("../LoggingService");

var eventType = require("../events/EventType");

var isActive = function isActive() {
  var isDefined = !!window.utag && !!window.utag.track;
  if (!isDefined) return false;
  var isTrackingAvailable = typeof window.utag.track === 'function';
  return isTrackingAvailable;
};

exports.isActive = isActive;

var isValidEventType = function isValidEventType(type) {
  var et = eventType;

  for (var _i = 0, _a = Object.keys(et); _i < _a.length; _i++) {
    var typeKey = _a[_i];
    if (type === et[typeKey]) return true;
  }

  return false;
};

var isValidEventPayload = function isValidEventPayload(eventPayload) {
  return Object.prototype.toString.call(eventPayload) === '[object Object]';
};

var track = function track(type, payload) {
  var trackingMsg = 'Failed to send tracking to tealium,';

  var getLog = function getLog() {
    return ["type:" + type, "payload:" + payload];
  };

  if (!isActive()) return logger.logError(trackingMsg + " tracking services unavailable", {}, getLog());
  if (!isValidEventType(type)) return logger.logError(trackingMsg + " event type invalid", {}, getLog());
  if (!isValidEventPayload(payload)) return logger.logError(trackingMsg + " event payload invalid", {}, getLog());

  try {
    window.utag.track(type, payload);
  } catch (e) {
    logger.logError(trackingMsg + "\xB7tracking\xB7error", e);
  }
};

exports.track = track;

},{"../LoggingService":7,"../events/EventType":9}]},{},[3]);
